<template>
  <div>
    <v-main>
      <v-row gutters="2" class="pt-0 pb-0" justify="end">
        <v-col cols="12" md="12" class="text-right">
          <Buttons
            :text-cancel="$t('GENERAL.CANCEL')"
            @cancel="cancel"
            :text-save="$t('GENERAL.CONTINUE')"
            @save="save"
          />
        </v-col>
      </v-row>
      <validation-observer ref="observer">
        <v-row gutters="2" class="pt-0 pb-0">
          <v-col cols="12" md="8" class="pt-0 pb-0">
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PROMOTIONS.FORM.PROMOTION_INFORMATION")
              }}</v-card-title>
              <v-card-text>
                <!-- Row Field Name -->
                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('PROMOTIONS.FORM.NAME')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="form.name"
                        dense
                        outlined
                        :error-messages="errors"
                        :label="$t('PROMOTIONS.FORM.NAME')"
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <!-- Row Field Name -->
                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('PROMOTIONS.FORM.DESCRIPTION')"
                    >
                      <v-textarea
                        v-model="form.description"
                        dense
                        outlined
                        :error-messages="errors"
                        :label="$t('PROMOTIONS.FORM.DESCRIPTION')"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>
                {{ $t("PROMOTIONS.FORM.ELEGIBLE_PRODUCTS") }}
              </v-card-title>
              <v-card-text v-if="courses.length > 0">
                <v-tabs fixed-tabs>
                  <v-tab>{{ $t("PROMOTIONS.FORM.COURSES") }}</v-tab>
                  <v-tab>{{ $t("PROMOTIONS.FORM.ACCOMMODATION") }}</v-tab>
                  <v-tab>{{ $t("PROMOTIONS.FORM.SERVICES") }} </v-tab>

                  <v-tab-item style="padding-top: 10px;">
                    <ProductsTreeView
                      :input.sync="form.courses"
                      :selection="form.courses"
                      :data="courses"
                      @change="joinEligibleProducts"
                    />
                  </v-tab-item>
                  <v-tab-item style="padding-top: 10px;">
                    <ProductsTreeView
                      :input.sync="form.accommodations"
                      :selection="form.accommodations"
                      :data="rooms"
                      key-name="room_with_lodging"
                      @change="joinEligibleProducts"
                    />
                  </v-tab-item>
                  <v-tab-item style="padding-top: 10px;">
                    <ProductsTreeView
                      :input.sync="form.services"
                      :selection="form.services"
                      :data="addons"
                      @change="joinEligibleProducts"
                    />
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>

            <v-card>
              <v-alert type="info" border="left" elevation="2" colored-border>
                <strong>{{ $t("PROMOTIONS.NEW_PROMOTION_HELP_TEXT1") }}</strong>
                <br />
                {{ $t("PROMOTIONS.NEW_PROMOTION_HELP_TEXT2") }}
              </v-alert>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" class="pt-0 pb-0">
            <v-card class="mb-2">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="12">
                    <v-switch
                      v-model="form.isReady"
                      :label="`${$t('PROMOTIONS.PUBLISH')}`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>
                {{ $t("PROMOTIONS.MARKETS_COUNTRY_SELECTOR_TITLE") }}
              </v-card-title>
              <v-card-text>
                <MarketsCountriesSelector
                  :markets-countries-data="marketsCountriesSelected"
                  :input.sync="form.marketsCountriesSelected"
                  is-required
                ></MarketsCountriesSelector>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>{{
                $t("PROMOTIONS.VALID_TIME_FRAME")
              }}</v-card-title>
              <v-card-text>
                <p class="subtitle-1">
                  {{ $t("PROMOTIONS.VALID_FOR_BOOKINGS_MADE") }}...
                </p>

                <v-row align="baseline" class="mt-0 mb-0 pt-0 pb-0">
                  <v-col cols="12" md="6" class="mt-0 mb-0 pt-0 pb-0">
                    <DatePicker
                      :date="form.beginBookingExpiry"
                      :is-required="true"
                      :name="$t('GENERAL.FROM')"
                      :name-large="
                        `${$t('GENERAL.FROM')} ${$t('PROMOTIONS.BOOKINGS')}`
                      "
                      :input.sync="form.beginBookingExpiry"
                    />
                    <v-checkbox
                      style="margin-top: -15px;"
                      v-model="form.validBooking"
                      :label="$t('GENERAL.NEVER_EXPIRES')"
                      @change="form.endBookingExpiry = form.beginBookingExpiry"
                    />
                  </v-col>
                  <v-col
                    v-if="!form.validBooking"
                    cols="12"
                    md="6"
                    class="mt-0 mb-0 pt-0 pb-0"
                  >
                    <DatePicker
                      :date="form.endBookingExpiry"
                      :is-required="true"
                      :date-min="form.beginBookingExpiry"
                      :name="$t('GENERAL.TO')"
                      :input.sync="form.endBookingExpiry"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text>
                <p class="subtitle-1">
                  {{ $t("PROMOTIONS.VALID_FOR_START_DATES") }}...
                </p>

                <v-row align="baseline" class="mt-0 mb-0 pt-0 pb-0">
                  <v-col cols="12" md="6" class="mt-0 mb-0 pt-0 pb-0">
                    <DatePicker
                      :date="form.beginStartDateExpiry"
                      :is-required="true"
                      :name="$t('GENERAL.FROM')"
                      :name-large="
                        `${$t('GENERAL.FROM')} ${$t('PROMOTIONS.START_DATES')}`
                      "
                      :input.sync="form.beginStartDateExpiry"
                    />
                    <v-checkbox
                      style="margin-top: -15px;"
                      v-model="form.validStartDate"
                      :label="$t('GENERAL.NEVER_EXPIRES')"
                      @change="
                        form.endStartDateExpiry = form.beginStartDateExpiry
                      "
                    />
                  </v-col>
                  <v-col
                    v-if="!form.validStartDate"
                    cols="12"
                    md="6"
                    class="mt-0 mb-0 pt-0 pb-0"
                  >
                    <DatePicker
                      :date="form.endStartDateExpiry"
                      :is-required="true"
                      :date-min="form.beginStartDateExpiry"
                      :name="$t('GENERAL.TO')"
                      :input.sync="form.endStartDateExpiry"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </validation-observer>
    </v-main>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import { mapActions, mapGetters } from "vuex";
import MarketsCountriesSelector from "@/view/content/components/MarketsCountriesSelector";
import Buttons from "@/view/content/components/Buttons";
import DatePicker from "@/view/content/components/DatePicker";
import { STORE_PROMOTION } from "@/core/services/store/general/promotion.module";

export default {
  name: "promotions-new",
  components: {
    ValidationProvider,
    ValidationObserver,
    ProductsTreeView,
    MarketsCountriesSelector,
    Buttons,
    DatePicker,
  },
  data() {
    return {
      errors: [],
      marketsCountriesSelected: { isEligibleAllCountries: 1 },
      form: {
        promotionId: null,
        isReady: 0,
        isDuplicate: false,
        typesTotal: 0,
        name: null,
        description: null,
        courses: [],
        accommodations: [],
        services: [],
        marketsCountriesSelected: { isEligibleAllCountries: 1 },
        beginBookingExpiry: null,
        endBookingExpiry: null,
        beginStartDateExpiry: null,
        endStartDateExpiry: null,
        validBooking: true,
        validStartDate: true,
        eligibleProducts: "",
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PROMOTIONS.TITLE"), route: "/promotions" },
      { title: this.$t("PROMOTIONS.NEW_PROMOTION") },
    ]);

    this.getCourses();
    this.getAddons();
    this.getRooms();
  },
  methods: {
    ...mapActions([
      "getCourses",
      "getAddons",
      "getRooms",
      "getPromotion",
      "updatePromotion",
    ]),
    async save() {
      if (await this.$refs.observer.validate()) {
        this.form.eligibleProducts = this.joinEligibleProducts();

        const response = await this.$store.dispatch(STORE_PROMOTION, this.form);

        if (response) {
          await this.$bvToast.toast(this.$t("GENERAL.SAVED_SUCCESS"), {
            title: "Success",
            variant: "success",
            solid: true,
          });

          await this.$router.push({
            name: "promotions-edit",
            params: { id: response.promotionId },
          });
        }
      } else {
        this.form.isDuplicate = false;
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    joinEligibleProducts() {
      let eligibleProducts = [];

      for (let i = 0; i < this.form.courses.length; i++) {
        eligibleProducts.push(this.form.courses[i]["id"]);
      }

      for (let i = 0; i < this.form.accommodations.length; i++) {
        eligibleProducts.push(this.form.accommodations[i]["id"]);
      }

      for (let i = 0; i < this.form.services.length; i++) {
        eligibleProducts.push(this.form.services[i]["id"]);
      }

      return eligibleProducts.join(",");
    },
    formatDate(date) {
      if (this.$moment(date, "YYYY-MM-DD").isValid()) {
        return this.$moment(date, "YYYY-MM-DD").format(this.campus.date_format);
      }
    },
    async cancel() {
      await this.$router.push({
        name: "promotions",
      });
    },
  },
  computed: {
    ...mapGetters(["courses", "rooms", "addons", "promotion", "campus"]),
  },
  watch: {
    $route: "loadData",
  },
};
</script>
